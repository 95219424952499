import { lazy } from "react";
import {
  loadClientSecret,
  loadQuestions,
  loadReferral,
  userTempToken,
  loadAllCourses,
  loadCoursesCategories,
  loadCourseLessons,
  loadAdminCourses,
  loadAdminCourse,
  loadUserInfo,
} from "../loaders/pageLoadable";

import { RequireAuth } from "react-auth-kit";
import ErrorBoundary from "../modules/shared/components/ErrorBoundary";
import ErrorHandler from "../modules/shared/components/ErrorHandler";
import ApprovalDeclined from "../modules/auth/ApprovalDeclined";

const Register = lazy(() => import("../modules/auth/Register"));
const Login = lazy(() => import("../modules/auth/Login"));
const ApprovalPending = lazy(() => import("../modules/auth/ApprovalPending"));
const ForgotPassword = lazy(() => import("../modules/auth/ForgotPassword"));
const Thankyou = lazy(() => import("../pages/Thankyou"));
const ResetPassword = lazy(() => import("../modules/auth/ResetPassword"));
const Welcome = lazy(() => import("../modules/lead/Welcome"));
const Webinar = lazy(() => import("../modules/lead/Webinar"));
const Home = lazy(() => import("../pages/Welcome"));

/*** Portal Pages ***/
const Dashboard = lazy(() => import("../modules/dashboard/Dashboard"));
const Question = lazy(() => import("../modules/questionnaire/Question"));
const Answer = lazy(() => import("../modules/questionnaire/Answer"));
const CourseCategories = lazy(() =>
  import("../modules/courses/CourseCategories")
);
const Courses = lazy(() => import("../modules/courses/Courses"));
const WatchLessons = lazy(() => import("../modules/courses/WatchLessons"));
const MeetingSchedule = lazy(() =>
  import("../modules/questionnaire/MeetingSchedule")
);
const GuestMeetingSchedule = lazy(() =>
  import("../modules/questionnaire/GuestMeetingSchedule")
);
const AdminCourseListing = lazy(() =>
  import("../modules/admin-courses/CourseListing")
);
const AdminCourseGenerator = lazy(() =>
  import("../modules/admin-courses/CourseGenerator")
);
const AdminQuickLinks = lazy(() =>
  import("../modules/admin-quick-links/QuickLinksListing")
);
const AdminGuestUserRequests = lazy(() =>
  import("../modules/admin-guest-user-requests/GuestUsersListing")
);

/*** Challenges Pages ***/
const Challenges = lazy(() => import("../modules/challenges/ChallengesDashboard"));
const CreateChallenges = lazy(() =>
  import("../modules/challenges/CreateChallenges")
);
const ChallengesListing = lazy(() =>
  import("../modules/challenges/ChallengesListing")
);
const TasksListing = lazy(() =>
  import("../modules/challenges/TaskListing")
);
const UserTaskResponse = lazy(() =>
  import("../modules/challenges/components/UserTaskResponse")
);
const ChallengesLeaderBoard = lazy(() =>
  import("../modules/challenges/LeaderBoard")
);

/*** Admin Guest Requests Pages ***/
const Pending = lazy(() => import("../modules/guest-user/Pending"));
const Approved = lazy(() => import("../modules/guest-user/Approved"));
const Declined = lazy(() => import("../modules/guest-user/Declined"));

/*** Error Pages ***/
const Error404 = lazy(() => import("../pages/Error404"));
const Error500 = lazy(() => import("../pages/Error500"));

/*** Promote Pages ***/
const Introduction = lazy(() => import("../modules/promote/Introduction"));
const Setting = lazy(() => import("../modules/promote/Setting"));
const PromoteFunnel = lazy(() => import("../modules/promote/Funnel"));
const Stats = lazy(() => import("../modules/promote/Stats"));
const FunnelLink = lazy(() => import("../modules/promote/FunnelLink"));
const Guests = lazy(() => import("../modules/promote/Guests"));
const Members = lazy(() => import("../modules/promote/Members"));
const Leads = lazy(() => import("../modules/promote/Leads"));

/*** Calendar Pages ***/
const Calendar = lazy(() => import("../modules/calendar/Calendar"));

/*** Profile Pages ***/
const Profile = lazy(() => import("../modules/account/Profile"));
const Payment = lazy(() => import("../modules/account/Payment"));
const AdvisorSetting = lazy(() => import("../modules/account/AdvisorSetting"));
const UpdatePassword = lazy(() => import("../modules/account/UpdatePassword"));
const UserInfo = lazy(() => import("../modules/account/UserInfo"));

/*** Advisor Questionnaires Pages ***/
const AdvisorQuestionnaires = lazy(() =>
  import("../modules/questionnaire/AdvisorQuestionnaires")
);

/*** Notification Pages ***/
// const UserNotification = lazy(() => import("../modules/notifications/UserNotification"));
const AllNotification = lazy(() =>
  import("../modules/notifications/AllNotification")
);
const CreateNotification = lazy(() =>
  import("../modules/admin-notifications/AdminNotification")
);
/*** Support Page ***/
const Support = lazy(() => import("../modules/support/Support"));

/*** Leaderboard Page ***/
const Leaderboard = lazy(() => import("../modules/leaderboard/Leaderboard"));

/*** Remove Account Page ***/
const DeleteAccountTerms = lazy(() =>
  import("../modules/delete-account-terms/DeleteAccountTerms")
);

/*** Account Terms Page ***/
const AccountTerms = lazy(() =>
  import("../modules/account-terms/AccountTerms")
);

export const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/welcome",
    element: <Welcome />,
    loader: loadReferral,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
  },
  {
    path: "/webinar",
    element: <Webinar />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/pending-approval",
    element: <ApprovalPending />,
  },
  {
    path: "/declined-approval",
    element: <ApprovalDeclined />,
  },
  {
    path: "/register",
    element: <Register />,
    loader: loadClientSecret,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
  },
  {
    path: "/thank-you",
    element: <Thankyou />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/password-reset/:resetToken",
    element: <ResetPassword />,
  },
  {
    path: "/dashboard",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    path: "/questionnaire",
    loader: loadQuestions,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <Question />
      </RequireAuth>
    ),
  },
  {
    path: "/support",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Support />
      </RequireAuth>
    ),
  },
  {
    path: "/answer/:user_id",
    loader: loadQuestions,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <Answer />
      </RequireAuth>
    ),
  },
  {
    path: "/promote",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Introduction />
      </RequireAuth>
    ),
  },
  {
    path: "/promote/setting",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Setting />
      </RequireAuth>
    ),
  },
  {
    path: "/promote/funnel",
    element: (
      <RequireAuth loginPath={"/login"}>
        <PromoteFunnel />
      </RequireAuth>
    ),
  },
  {
    path: "/promote/stats",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Stats />
      </RequireAuth>
    ),
  },
  {
    path: "/promote/funnel/:funnelType",
    element: (
      <RequireAuth loginPath={"/login"}>
        <FunnelLink />
      </RequireAuth>
    ),
  },
  {
    path: "/calendar",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Calendar />
      </RequireAuth>
    ),
  },
  {
    path: "/promote/guest",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Guests />
      </RequireAuth>
    ),
  },
  {
    path: "/promote/members",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Members />
      </RequireAuth>
    ),
  },
  {
    path: "/promote/leads",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Leads />
      </RequireAuth>
    ),
  },
  {
    path: "/guest-user/pending-request",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Pending />
      </RequireAuth>
    ),
  },
  {
    path: "/guest-user/approved-request",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Approved />
      </RequireAuth>
    ),
  },
  {
    path: "/guest-user/declined-request",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Declined />
      </RequireAuth>
    ),
  },
  {
    path: "/profile/:userId",
    loader: loadUserInfo,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <UserInfo />
      </RequireAuth>
    ),
  },
  {
    path: "/meeting-schedule/:userId",
    loader: userTempToken,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: <MeetingSchedule />,
  },
  {
    path: "/guest-meeting-schedule/:userId",
    loader: userTempToken,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: <GuestMeetingSchedule />,
  },
  {
    path: "/admin/courses",
    loader: loadAdminCourses,
    errorBoundary: <ErrorBoundary />,
    errorElement: <ErrorHandler />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <AdminCourseListing />
      </RequireAuth>
    ),
  },
  {
    path: "/admin/courses/:courseId",
    loader: loadAdminCourse,
    errorBoundary: <ErrorBoundary />,
    errorElement: <ErrorHandler />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <AdminCourseGenerator />
      </RequireAuth>
    ),
  },
  {
    path: "/course-categories",
    loader: loadCoursesCategories,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <CourseCategories />
      </RequireAuth>
    ),
  },
  {
    path: "/courses",
    loader: loadAllCourses,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <Courses />
      </RequireAuth>
    ),
  },
  {
    path: "/course-categories/courses/:courseId/lessons",
    loader: loadCourseLessons,
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <WatchLessons />
      </RequireAuth>
    ),
  },
  {
    path: "/quick-links",
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <AdminQuickLinks />
      </RequireAuth>
    ),
  },
  {
    path: "/guest-user-requests",
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <AdminGuestUserRequests />
      </RequireAuth>
    ),
  },
  {
    path: "/account",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Profile />
      </RequireAuth>
    ),
  },
  {
    path: "/account/settings",
    element: (
      <RequireAuth loginPath={"/login"}>
        <AdvisorSetting />
      </RequireAuth>
    ),
  },
  {
    path: "/account/security",
    element: (
      <RequireAuth loginPath={"/login"}>
        <UpdatePassword />
      </RequireAuth>
    ),
  },
  {
    path: "/account/payment",
    loader: loadClientSecret,
    element: (
      <RequireAuth loginPath={"/login"}>
        <Payment />
      </RequireAuth>
    ),
  },
  {
    path: "/leaderboard",
    element: (
      <RequireAuth loginPath={"/login"}>
        <Leaderboard />
      </RequireAuth>
    ),
  },
  {
    path: "/create-notifications",
    element: (
      <RequireAuth loginPath={"/login"}>
        <CreateNotification />
      </RequireAuth>
    ),
  },
  {
    path: "/notifications",
    element: (
      <RequireAuth loginPath={"/login"}>
        <AllNotification />
      </RequireAuth>
    ),
  },
  {
    path: "/advisor-questionnaire",
    element: (
      <RequireAuth loginPath={"/login"}>
        <AdvisorQuestionnaires />
      </RequireAuth>
    ),
  },
  {
    path: "/challenges",
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <Challenges />
      </RequireAuth>
    ),
  },
  {
    path: "/challenges/create",
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <CreateChallenges />
      </RequireAuth>
    ),
  },
  {
    path: "/challenges/listing",
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <ChallengesListing />
      </RequireAuth>
    ),
  },
  {
    path: "/tasks/listing",
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <TasksListing />
      </RequireAuth>
    ),
  },
  {
    path: "/tasks/response",
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <UserTaskResponse />
      </RequireAuth>
    ),
  },
  {
    path: "/challenges/leaderboard",
    errorBoundary: <ErrorBoundary />,
    errorElement: <Error500 />,
    element: (
      <RequireAuth loginPath={"/login"}>
        <ChallengesLeaderBoard />
      </RequireAuth>
    ),
  },
  {
    path: "/server",
    element: <Error500 />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
  {
    path: "/delete-account-terms",
    element: <DeleteAccountTerms />,
  },
  {
    path: "/account-terms",
    element: <AccountTerms />,
  },
];
